.swiper {
  width: 500px;
  height: auto;
}

.swiper-button-next { color: #800000; }
.swiper-button-prev { color: #800000; }
.swiper-pagination-bullet { background-color: #800000; }

.skillsSwiper {
  width: 90%;
}
.capstoneSwiper {
  width: 100%;
}
.skillsSwiperSlide {
  width: 80%;
}

.ml4 {
  position: relative;
  font-weight: 900;
  font-size: 4.5em;
}
.ml4 .letters {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0.3em;
  right: 0;
  z-index: 999;
  opacity: 0; 
}
